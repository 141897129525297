.DescriptionBox {
    margin: 70px 170px;
  .descriptionbox-navigator {
    display: flex;
    .descriptionbox-nav-box {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        width: 130px;
        height: 50px;
        border: 1px solid #d0d0d0;
    }
    .fade{
        background-color: #fbfbfb;
        color: #555;
    }
  }
  .descriptionbox-description{
    display: flex;
    flex-direction: column;
    gap: 25px;
    border: 1px solid #d0d0d0;
    padding: 48px;
    padding-bottom: 70px;
    text-align: left;
  }
}
