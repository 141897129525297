.Newcollections{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 50px;
    h1{
        color: #171717;
        font-size: 50px;
        font-weight: 600;
    }
    hr{
        width: 200px;
        height: 6px;
        border-radius: 10px;
        background: #252525;
    }
    .collections{
        display: grid;
        padding: 0px 170px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 50px;
        gap: 30px;
    }
}
