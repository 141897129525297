.NewsLetter {
  width: 65%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 140px;
  margin-bottom: 50px;
  background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
  gap: 30px;
  h1{
    color: #454545;
    font-size: 55px;
    font-weight: 600;
  }
  p{
    color: #454545;
    font-size: 20px;
  }
  div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    width: 730px;
    height: 50px;
    border-radius: 80px;
    border: 1px solid #e3e3e3;
    input{
        width: 500px;
        margin-left: 30px;
        border: none;
        outline: none;
        color: #616161;
        font-family: poppins;
        font-size: 16px;
    }
    button{
        width: 210px;
        height: 50px;
        border-radius: 80px;
        background-color: black;
        color: white;
        font-size: 16px;
        cursor: pointer;
    }
  }
}
