.Items {
    img {
      width: 276px;
      height: 311px;
    }
  .item-prices {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .item-prices-new {
      font-size: 30px;
    }
    .item-prices-old {
      font-size: 20px;
      text-decoration: line-through;
    }
  }
}
