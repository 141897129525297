.Hero{
    background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
    display: flex;
    .hero-left{
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        padding-left: 180px;
        line-height: 1.1;
        h2{
            color: #090909;
            font-size: 26px;
            text-align: left;
            font-weight: 600;
        }
        p{
            color: #171717;
            font-size: 100px;
            font-weight: 700;
            text-align: left;
        }
        .hero-hand-icon{
            display: flex;
            align-items: center;
            gap: 20px;
            img{
                width: 105px;
            }
        }
        .hero-latest-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            width: 310px;
            height: 70px;
            border-radius: 75px;
            margin-top: 30px;
            background: #ff4141;
            color: white;
            font-size: 22px;
            font-weight: 600;
        }
    }
    .hero-right img{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

    }
}
