.Navbar {
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 1px 3px -2px black;
  padding: 1rem;
  .nav-logo {
    :link{
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
    }
    span {
      font-size: 30px;
      font-weight: 400;
      color: #171717;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: #626262;
    margin: 0;
    font-size: 20px;
    font-weight: 400px;
    list-style: none;
    li {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      text-decoration: none;
      hr {
        align-self: center;
        border: none;
        width: 80%;
        height: 3px;
        border-radius: 10px;
        background: #ff4141;
      }
    }
  }
  .nav-right {
    display: flex;
    align-items: center;
    position: relative;
    gap: 40px;
    button {
      border: 1px solid #7a7a7a;
      outline: none;
      padding: 10px 40px;
      border-radius: 30px;
      cursor: pointer;
      color: #515151;
      background: white;
      &:active {
        background: #f3f3f3;
      }
    }
    img {
        cursor: pointer;
        }
    .nav-cart-count {
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 15px;
      background-color: red;
      color: white;
      font-size: 14px;
      position: absolute;
      right: -8px;
      top: -4px;
    }
  }
}
