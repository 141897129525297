.Breadcrums{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    color: #8e8e8e;
    font-size: 12px;
    font-weight: 600;
    margin: 25px 170px;
    text-transform: capitalize;
}