.Offers {
  width: 60%;
  height: 80vh;
  display: flex;
  margin: auto;
  padding: 0px 140px;
  margin-bottom: 50px;
  background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
  text-align: left;
  .offers-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      color: #171717;
      font-size: 80px;
      font-weight: 600;
    }
    p {
      color: #171717;
      font-size: 22px;
      font-weight: 600;
    }
    button {
      width: 282px;
      height: 70px;
      border-radius: 35px;
      background-color: #ff4141;
      border: none;
      color: white;
      font-size: 22px;
      font-weight: 500;
      margin-top: 30px;
      cursor: pointer;
      outline: none;
    }
  }
  .offers-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 50px;
  }
}
